.text-muted {
  color: #b5b5c3 !important;
  font-size: x-small;
}
.settleDuesModal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border: none;
  box-shadow: 24;
  font-family: var(--mavenpro);
  .settleDuesWrapper {
    display: flex;
    flex-direction: column;
    .settleDuesHeader {
      position: relative;
      width: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      .settleDuesHeading {
        background-color: transparent;
        font-size: 15px;
        .roomIdHeader {
          display: inline-grid;
          margin-left: 2rem;
          width: 100px;
        }
      }
      .close {
        font-family: var(--mavenpro);
        font-size: 25px;
        font-weight: 500;
        cursor: pointer;
      }
    }
    .amountWrapper {
      display: flex;
      flex-direction: column;
      .fieldWrapper {
        margin-top: 25px;
      }
      input[type="number"]::-webkit-inner-spin-button,
      input[type="number"]::-webkit-outer-spin-button {
        opacity: 0;
      }
    }
  }
  .settleDuesSubmitBtns {
    position: relative;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: 5px;
    margin-bottom: 5px;
    .cancelBtn {
      font-size: 15px;
      font-weight: 500;
      font-family: var(--mavenpro);
      border: none;
      background-color: transparent;
    }
    .submit {
      font-size: 15px;
      font-family: var(--mavenpro);
      border: none;
      background-image: var(--backgroundImg);
      height: 42px;
      width: 95px;
      border-radius: 5px;
      color: white;
    }
    :disabled {
      background-color: lightgrey;
      color: #222;
      font-weight: 500;
      background-image: none;
    }
  }
}
