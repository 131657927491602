.ag-theme-alpine {
  --ag-grid-size: 5px;
  --ag-list-item-height: 20px;
  --ag-font-size: 10px;
}
.custom-header {
  font-size: 1.8ch;
}

.custom-row-class-1 {
  background-color: #ff0000;
}
.custom-row-class-2 {
  background-color: #fff444;
}
