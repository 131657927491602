@import url("https://fonts.googleapis.com/css2?family=Maven+Pro:wght@400;500;600;700;800;900&display=swap");
body {
  margin: 0;
  /* font-family: "Maven Pro", sans-serif !important ;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */

  font-size: 13px !important;
  font-weight: 400;
  font-family: Poppins, Helvetica, "sans-serif";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
