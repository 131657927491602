.modal {
  height: 100%;
  flex-direction: row-reverse;
  animation: openModal 0.2s ease-in;
}
.myModalBody142321 {
  margin: 0 0 0 auto !important;
  max-width: 80vw !important;
  height: 100vh !important;
}
.myModalContentssdsx {
  border-radius: 0;
  height: 100vh;
  overflow-y: auto;
}

@keyframes openModal {
  0% {
    transform: translate(60vw, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.react-es-searchbox input {
  width: 35rem;
  height: 3rem;
  border-radius: 5px;
  margin-top: 1rem;
  border: 2px solid #dddddd;
  margin-left: 2rem;
}

.react-es-searchbox input {
  padding-left: 1rem;
}

.react-es-searchbox :focus {
  border: 2px solid #dddddd;
}

.react-es-results {
  color: black;
}

.react-es-searchbox input:focus-visible {
  border: 2px solid #dddddd !important;
  outline: #dddddd;
}

.react-es-pagination {
  display: none;
}

.myModalBodyEditpassword {
  margin: 0 0 0 auto !important;
  max-width: 40vw !important;
  height: 100vh !important;
}
.myModalContentEditpassword {
  border-radius: 0;
  height: 100vh;
  overflow-y: auto;
}
